import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = ({ setDisplayQrCode }) => {
  return (
    <>
      <header className="header">
        <div className="wide-title-parent">
          <Link to="/">
            <h1 className="title title-wide">ImmoFinder24</h1>
            <h2 className="subtitle">
              Der plattformübergreifende Immobiliensucher mit Push
              Benachrichtigung
            </h2>
          </Link>
        </div>
        <div className="header-logo">
          <div className="header-logo-img">
            <Link to="/">
              <img className="logo" src="images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="header-space">
            <Link to="/">
              <div className="narrow-title-parent">
                <p className="title title-narrow">ImmoFinder24</p>
                <h2 className="subtitle">
                  Der plattformübergreifende Immobiliensucher mit Push
                  Benachrichtigung
                </h2>
              </div>
            </Link>
            <div className="google-play-area">
              <a
                href="https://play.google.com/store/apps/details?id=com.bennoss.ImmoFinder24"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="google-play"
                  src="images/google-play-badge.png"
                  alt="google play"
                />
              </a>
              <img
                onClick={() => setDisplayQrCode(true)}
                className="qrcode"
                src="images/google-play-qr-code.png"
                alt="google play qr code"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
