import React from "react";

const Right = () => {
  return (
    <>
      <aside className="right">
        <img className="screenshot" src="images/first.jpg" alt="screenshot" />
        <br />
        <img className="screenshot" src="images/second.jpg" alt="screenshot" />
        <br />
        <img className="screenshot" src="images/third.jpg" alt="screenshot" />
        <br />
        <img className="screenshot" src="images/fourth.jpg" alt="screenshot" />
        <br />
        <img className="screenshot" src="images/fifth.jpg" alt="screenshot" />
        <br />
        <img className="screenshot" src="images/sixth.jpg" alt="screenshot" />
      </aside>
    </>
  );
};

export default Right;
