import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./body.css";

const Center = () => {
  const [displayFurtherInformation, setDisplayFurtherInformation] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const displayInformation = (index) => {
    console.log("Display further infos for index: " + index);
    let tmpHours = [];
    tmpHours = displayFurtherInformation.map((item) => false);
    if (displayFurtherInformation[index]) {
      tmpHours[index] = false;
    } else {
      tmpHours[index] = true;
    }
    setDisplayFurtherInformation(tmpHours);
  };
  return (
    <>
      <main className="body-center">
        <section className="box introduction-box">
          <h2 className="box-title">
            ImmoFinder, die 24 Stunden verfügbare Android™ App für die
            Immobiliensuche
          </h2>
          <p>
            ImmoFinder24 ist der perfekte Begleiter bei der Suche nach einer
            neuen WG, Wohnung, dem neuen Haus, etc.
          </p>
          <p>Die App kennt kein Schlafen, keine Arbeit oder Freizeit.</p>
          <p>
            Sie sucht immer auf allen eingerichteten Immobilienportalen nach
            neuen Angeboten und informiert sofort.
          </p>
        </section>

        <section id="disadvantages-common-search" className="box">
          <h2 className="box-title title-advantage-disadvantage">
            Vorteile von ImmoFinder24
          </h2>
          <ul className="advantage-disadvantage" id="advantage">
            <li onClick={(e) => displayInformation(0)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsUp.png"
                  alt="bad"
                />
                <p className="thumpText">Bündelt sehr viele Portale</p>
              </div>
              {displayFurtherInformation[0] && (
                <div className="arrow_box">
                  <p>
                    Die App kann Suchaufträge von verschiedensten
                    Immobilienportale bündeln.
                  </p>
                  <p>
                    Dadurch muss man auf der Suche nicht ständig mehrere
                    Internetseiten überprüfen.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(1)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsUp.png"
                  alt="good"
                />
                <p className="thumpText">
                  Benachrichtigt bei neu verfügbaren Wohnungsangeboten
                </p>
              </div>
              {displayFurtherInformation[1] && (
                <div className="arrow_box">
                  <p>
                    Der größte Vorteil der App ist die sofortige
                    Benachrichtigung bei neu gefundenen Angeboten.
                  </p>
                  <p>
                    Bei guten Angeboten heißt es schnell anfragen, denn der
                    Vermieter/Makler gibt meist nur den ersten Interessenten
                    einen Besichtigungstermin.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(2)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsUp.png"
                  alt="good"
                />
                <p className="thumpText">
                  Schneller, weniger Arbeit, weniger Stress
                </p>
              </div>
              {displayFurtherInformation[2] && (
                <div className="arrow_box">
                  <p>
                    Durch die App ist man nicht permanent gezwungen selbst nach
                    neuen Angeboten zu suchen.
                  </p>
                  <p>
                    Die App übernimmt die Arbeit für einen, dadurch ist man
                    schneller und entspannter als alle Anderen.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(3)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsUp.png"
                  alt="good"
                />
                <p className="thumpText">100% personalisierbare Suche</p>
              </div>
              {displayFurtherInformation[3] && (
                <div className="arrow_box">
                  <p>
                    Die in der App gespeicherten Suchen können mit allen auf den
                    Immobilienportalen angebotenen Filtern eingestellt werden.
                  </p>
                  <p>
                    Unnötige Benachrichtigungen lassen sich damit vermeiden.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(4)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsUp.png"
                  alt="good"
                />
                <p className="thumpText">
                  Regelmäßige, automatische Aktualisierung
                </p>
              </div>
              {displayFurtherInformation[4] && (
                <div className="arrow_box">
                  <p>
                    Die App durchsucht die eingerichteten Immobilienportale
                    permanent und automatisch im Hintergrund.
                  </p>
                  <p>
                    Als Nutzer muss man nur noch auf neue Angebote reagieren.
                  </p>
                </div>
              )}
            </li>
          </ul>
        </section>

        <section id="disadvantages-common-search" className="box">
          <h2 className="box-title title-advantage-disadvantage">
            Löst folgende Probleme der herkömmlichen Immobiliensuche
          </h2>
          <ul className="advantage-disadvantage" id="disadvantage">
            <li onClick={(e) => displayInformation(5)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsDown.png"
                  alt="bad"
                />
                <p className="thumpText">
                  Permanent mehrere Immobilienportale überprüfen
                </p>
              </div>
              {displayFurtherInformation[5] && (
                <div className="arrow_box">
                  <p>
                    Es gibt schier unendlich viele Immobilienportale im
                    Internet.
                  </p>
                  <p>
                    Den Überblick über alle zu behalten ist quasi unmöglich.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(6)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsDown.png"
                  alt="bad"
                />
                <p className="thumpText">
                  Zeitaufwändige, anstrengende und frustrierende Suche
                </p>
              </div>
              {displayFurtherInformation[6] && (
                <div className="arrow_box">
                  <p>
                    Die Immobiliensuche verschlingt meist viel Zeit und ist
                    schlussendlich doch erst nach langem Suchen erfolgreich.
                  </p>
                  <p>
                    Mit viel Mühe eine Anfrage formuliert und abgeschickt? Oft
                    bekommt man dann nicht einmal eine Antwort, weil es bereits
                    zu viele Anfragen gibt. Dies ist sehr frustrierend.
                  </p>
                </div>
              )}
            </li>
            <li onClick={(e) => displayInformation(7)}>
              <div className="advantage-disadvantage-div-item">
                <img
                  className="thump"
                  src="images/icons_style/thumbsDown.png"
                  alt="bad"
                />
                <p className="thumpText">
                  Die besten Angebote sind meist schnell vergeben
                </p>
              </div>
              {displayFurtherInformation[7] && (
                <div className="arrow_box">
                  <p>
                    Bei guten Angeboten muss man schnell reagieren! Am besten
                    direkt nach der Veröffentlichung Interesse bekunden.
                  </p>
                  <p>
                    Gute Angebote bleiben oft nur für wenige Stunden online. Wer
                    nicht direkt reagiert hat seine Chance meist verpasst.
                  </p>
                </div>
              )}
            </li>
          </ul>
        </section>

        <section className="box">
          <h2 className="box-title">Unterstütze Immobilienportale</h2>
          <p>
            Die App unterstützt derzeit gut{" "}
            <Link to="/portals">
              <span className="link">60 Immobilienportale</span>
            </Link>{" "}
            vor allem in Deutschland, Österreich, der Schweiz und Spanien.
          </p>
          <p>Die meisten Portale davon sind kostenlos nutzbar.</p>
          <div className="supportedCountriesFlags">
            <img src="images/flagGermany.png" alt="Deutschland" />
            <img src="images/flagAustria.png" alt="Österreich" />
            <img src="images/flagSwitzerland.png" alt="Schweiz" />
            <img src="images/flagSpain.png" alt="Spanien" />
          </div>
        </section>
        <section id="steps" className="box">
          <h2 className="box-title">Erste Schritte</h2>
          <p>
            Der Weg zur Wohnungsbesichtigung der neuen Traumwohnung benötigt
            zunächst nur ein paar (einmalige) Vorbereitungsschritte
          </p>
          <ul className="steps-items">
            <li>
              <img
                className="thump"
                src="images/icons_style/download.png"
                alt="download"
              />
              <div>
                <h3>ImmoFinder24 herunterladen</h3>
                <p>
                  App im{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.bennoss.ImmoFinder24"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Play Store
                  </a>{" "}
                  herunterladen und installieren.
                </p>
              </div>
            </li>
            <li>
              <img
                className="thump"
                src="images/icons_style/energy.png"
                alt="energy"
              />
              <div>
                <h3>Energiespareinstellung</h3>
                <p>
                  Die Energiespareinstellung für ImmoFinder24 anpassen. Dies ist
                  notwendig, da die App im Hintergrund nach neuen Wohnungen
                  sucht. Android würde den Suchdienst ansonsten nach einiger
                  Zeit aus Energiespargründen beenden.
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section id="steps" className="box">
          <h2 className="box-title">Der Weg zur neuen (Traum-)Wohnung</h2>
          <p>
            Nachdem die App installiert und die Energiespareinstellung angepasst
            ist, können nun die Suchaufträge angelegt werden.
          </p>
          <p>Hierfür sind pro Suche folgende Schritte notwendig:</p>
          <ul className="steps-items">
            <li>
              <img
                className="thump"
                src="images/icons_style/browser.png"
                alt="browser"
              />
              <div>
                <h3>Browser öffnen</h3>
                <p>Das gewünschte Immobilienportal in einem Browser öffnen.</p>
              </div>
            </li>
            <li>
              <img
                className="thump"
                src="images/icons_style/search.png"
                alt="search"
              />
              <div>
                <h3>Immobiliensuche durchführen</h3>
                <p>
                  Die angepasste Suche durchführen. Wichtig ist dabei, dass die
                  Suchfilter für ein optimales Ergebnis richtig eingestellt
                  sind.
                </p>
              </div>
            </li>
            <li>
              <img
                className="thump"
                src="images/icons_style/copy.png"
                alt="copy"
              />
              <div>
                <h3>URL der erstellten Suche kopieren</h3>
                <p>Die URL (Adresse) der Suche im Browser kopieren.</p>
              </div>
            </li>
            <li>
              <img className="thump" src="images/logo.png" alt="logo" />
              <div>
                <h3>Neuen Suchauftrag (in der App) erstellen</h3>
                <p>
                  ImmoFinder24 öffnen und eine neue Suche mit der soeben
                  kopierten URL erstellen.
                </p>
              </div>
            </li>
            <li>
              <img
                className="thump"
                src="images/icons_style/repeat.png"
                alt="repeat"
              />
              <div>
                <h3>Weitere Suchaufträge erstellen</h3>
                <p>
                  Alle vorherigen Schritte wiederholen bis alle Suchen der
                  gewünschten Immobilienportale in der App gespeichert sind.
                </p>
              </div>
            </li>
            <p>
              Anschließend auf neue Angebote warten, schnell anschreiben, viele
              Wohnungsbesichtigungen durchführen und schlussendlich in die
              Traumwohnung einziehen.
            </p>
          </ul>
        </section>
        <section id="price" className="box">
          <h2 className="box-title">Kosten</h2>
          <ul className="steps-items">
            <li>
              <img className="thump" src="images/free.png" alt="Kostenlos" />
              <div>
                <h3>Kostenlos</h3>
                <p>
                  Der Download sowie das Nutzen der App ist kostenlos. 96% aller
                  Immobilienportale können ebenso kostenlos genutzt werden.
                </p>
              </div>
            </li>
            <li>
              <img className="thump" src="images/crown.png" alt="Krone" />
              <div>
                <h3>Premiumportale</h3>
                <p>
                  Vereinzelte Immobilienportale können nicht direkt vom Handy
                  abgerufen werden. Für diese sogenannten Premiumportale müssen
                  Proxy Server verwendet werden. Diese Server sind
                  kostenpflichtig, daher veranschlagen wir für die Nutzung
                  dieser Premiumportale eine geringe Gebühr. Alle anderen
                  Immobilienportale können unabhängig davon kostenlos verwendet
                  werden.
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section id="energy" className="box">
          <h2 className="box-title">Energiespareinstellungen in Android</h2>
          <p>
            Da ImmoFinder24 nicht den Status von WhatsApp und Co hat, beendet
            Android (gerade bei neueren Versionen) den Suchdienst im Hintergrund
            oft aus Energiespargründen.
          </p>
          <p>
            Damit die App zuverlässig im Hintergrund läuft und über neu
            gefundene Immobilien benachrichtigt, müssen daher zwei Dinge
            beachtet werden:
          </p>
          <ul className="steps-items">
            <li>
              <img className="thump" src="images/energy.png" alt="Energie" />
              <div>
                <h3>Android Energiespareinstellung</h3>
                <p className="margin-left">
                  Die Energiespareinstellung im Android System muss deaktiviert
                  werden. Hierfür gibt es auf der Homepage von{" "}
                  <a
                    href="https://dontkillmyapp-com.translate.goog/?_x_tr_sl=auto&_x_tr_tl=de&_x_tr_hl=de&_x_tr_pto=nui"
                    target="_blank"
                    rel="noreferrer"
                  >
                    dontkillmyapp.com
                  </a>{" "}
                  eine Anleitung.
                </p>
              </div>
            </li>
            <li>
              <img className="thump" src="images/x.png" alt="Stop" />
              <div>
                <h3>Nicht komplett beenden</h3>
                <p className="margin-left">
                  Die App darf nicht komplett beendet werden (Wegswipen o.ä.).
                  Wenn man alle im Hintergrund laufenden Apps beendet, dann
                  sollte man die App anschließend wieder öffnen.
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section id="support" className="box">
          <h2 className="box-title">Spenden, Bewertung, Unterstützung</h2>
          <p>Die App war bei der Suche nach der Traumwohnung hilfreich?</p>
          <p>Dann gibt es gleich mehrere Möglichkeiten uns zu unterstützen!</p>
          <ul className="steps-items">
            <li>
              <img className="thump" src="images/donate.png" alt="Spenden" />
              <div>
                <h3>Spende</h3>
                <div className="paypal-donation">
                  <p>
                    Wir freuen uns immer über Spenden via{" "}
                    <a
                      href="https://www.paypal.com/donate?hosted_button_id=EL3Y58HQ6NXAS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Paypal
                    </a>
                    .
                  </p>
                  <form
                    className="paypal"
                    action="https://www.paypal.com/donate"
                    method="post"
                    target="_blank"
                  >
                    <input
                      type="hidden"
                      name="hosted_button_id"
                      value="EL3Y58HQ6NXAS"
                    />
                    <input
                      type="image"
                      src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_LG.gif"
                      border="0"
                      name="submit"
                      title="PayPal - The safer, easier way to pay online!"
                      alt="Donate with PayPal button"
                    />
                    <img
                      alt=""
                      border="0"
                      src="https://www.paypal.com/de_DE/i/scr/pixel.gif"
                      width="1"
                      height="1"
                    />
                  </form>
                </div>
              </div>
            </li>
            <li>
              <img className="thump" src="images/stars.png" alt="Sterne" />
              <div>
                <h3>Bewertung</h3>
                <p>
                  Wir freuen uns auch immer über eine 5 Sterne Bewertung im{" "}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.bennoss.ImmoFinder24"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Play Store
                  </a>
                  .
                </p>
                <p>
                  Eine Bewertung ist kostenlos und hilft uns unsere geniale App
                  weiter zu verbreiten.
                </p>
              </div>
            </li>
            <li>
              <img
                className="thump"
                src="images/recommend.png"
                alt="Empfehlen"
              />
              <div>
                <h3>Empfehlung</h3>
                <p>
                  Auch eine Empfehlung an Freunde und Bekannt ist kostenlos :)
                  und hilft sowohl uns, als auch ihnen.
                </p>
              </div>
            </li>
          </ul>
        </section>
        <section id="energy" className="box">
          <h2 className="box-title">Hilfe</h2>
          <ul className="steps-items">
            <li>
              <img className="thump" src="images/help.png" alt="help" />
              <div>
                <p>
                  Hilfe ist stets nur eine{" "}
                  <a href="mailto:immofinder24@gmail.com">Mail</a> entfernt
                </p>
                <p>
                  Sollte die App nicht so funktionieren wie erwartet (Fehler),
                  es konstruktive Kritik, Erweiterungsvorschläge oder der Wunsch
                  nach weiteren unterstützen Immobilienportale geben, dann gerne
                  eine E-Mail an{" "}
                  <a href="mailto:immofinder24@gmail.com">
                    immofinder24@gmail.com
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </section>
      </main>
    </>
  );
};

export default Center;
