import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Left from "./left";
import Right from "./right";
import Center from "./center";
import RealEstatePortals from "../realEstatePortals/realEstatePortals";
import Impressum from "../legal/impressum";
import DataProtection from "../legal/dataProtection";
import ScrollToTop from "../ScrollToTop";

const Body = ({ setDisplayQrCode, displayQrCode }) => {
  const history = useHistory();
  return (
    <>
      <div className="body-wrap">
        <div className="body">
          <Left />
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <Center
                setDisplayQrCode={setDisplayQrCode}
                displayQrCode={displayQrCode}
              />
            </Route>
            <Route path="/impressum">
              <Impressum />
            </Route>
            <Route path="/dataProtection">
              <DataProtection />
            </Route>
            <Route path="/portals">
              <div>
                <button className="backButton" onClick={() => history.goBack()}>
                  Zurück
                </button>
                <RealEstatePortals />
                <button className="backButton" onClick={() => history.goBack()}>
                  Zurück
                </button>
              </div>
            </Route>
            <Route path="*">
              <h1>Page not found!</h1>
            </Route>
          </Switch>

          <Right />
        </div>
      </div>
    </>
  );
};

export default Body;
