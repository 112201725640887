import React from "react";
import { Link } from "react-router-dom";
import "./legal.css";

const DataProtection = () => {
  return (
    <>
      <div>
        <div className="legal-title-parent">
          <h2 className="legal-title">Datenschutzerklärung</h2>
          <Link to="/">
            <button className="backButton legalBackButton">Home</button>
          </Link>
        </div>
        <div className="legal-container">
          <p>
            Wir achten sehr auf den Datenschutz unserer Nutzer. Aus diesem Grund
            sammelt diese Homepage keinerlei direkten Nutzerdaten.
          </p>
          <br />
          <p>
            Allerdings verwenden Google Analytics für eine aggregierte,
            anonymisierte Analyse des Websiteverkehrs. Um Ihre Sitzungsnutzung
            zu verfolgen, legt Google ein Cookie (_ga) mit einer zufällig
            generierten ClientID in Ihrem Browser ab. Diese ID ist anonymisiert
            und enthält keine identifizierbaren Informationen wie E-Mail,
            Telefonnummer, Name usw. Wir senden auch Ihre IP-Adresse an Google.
            Wir verwenden GA, um das gesamte Website-Verhalten nachzuverfolgen,
            z. B. welche Seiten Sie sich angesehen haben, wie lange und so
            weiter. Diese Informationen sind für uns wichtig, um das
            Nutzererlebnis zu verbessern und die Effektivität der Website zu
            bestimmen. Wenn Sie wissen möchten, welche Browsing-Informationen
            wir haben, oder uns bitten möchten ihr Google Analytics Daten zu
            löschen, dann löschen Sie bitte Ihre _ga-Cookies, kontaktieren Sie
            uns per <a href="mailto:ImmoFinder24@gmail.com">E-Mail</a> und/oder
            installieren Sie das{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              rel="noreferrer"
              target="_blank"
            >
              Google Analytics Opt-Out Browser Add-On.
            </a>
          </p>
          <br />
          <p>
            Die App ImmoFinder24 sammelt Analysedaten mittels ihrer IP-Adresse.
            Im Fehlerfall werden Suchaufträge ebenfalls zur Auswertung der
            Fehlerquelle gesammelt
          </p>
          <p>Nun folgt die Datenschutzerklärung der App ImmoFinder24</p>
          <br />
          <h3>Datenschutzerklärung der App ImmoFinder24</h3>
          <p>
            Danke, dass Sie ImmoFinder24 nutzen. Wenn Sie sich entscheiden,
            unseren Service zu nutzen, stimmen Sie der Sammlung und Verwendung
            von Informationen in Bezug auf diese Richtlinie zu. Wir werden Ihre
            Daten nur wie in dieser Datenschutzerklärung beschrieben verwenden
            oder weitergeben.
            <br />
            Wenn Sie Fragen oder Bedenken zu dieser Datenschutzerklärung oder
            unseren Praktiken in Bezug auf Ihre persönlichen Daten haben,
            kontaktieren Sie uns bitte unter{" "}
            <a href="mailto:ImmoFinder24@gmail.com">ImmoFinder24@gmail.com</a>
          </p>

          <h3>Informationen, die von uns gesammelt und ausgewertet werden</h3>

          <p>
            Für ein besseres Erlebnis bei der Nutzung unseres Dienstes und zur
            Nachvollziehbarkeit des App-Workflows möchten wir Sie darauf
            hinweisen, dass aufgetretene Fehler immer protokolliert und auf
            Ihrem Gerät gespeichert werden. Wenn Sie die Debug-Protokollierung
            aktivieren, werden detaillierte Informationen zum App-Ablauf
            gesammelt und auf Ihrem Gerät gespeichert. Diese Informationen
            werden nur auf Ihrem lokalen Gerät gespeichert und nicht von uns
            übertragen/gesammelt.
          </p>

          <p>
            Aufgetretene Fehler und Analysedaten werden von uns mit Countly nur
            gesammelt, wenn Sie zustimmen. Die Daten werden verschlüsselt an
            einen Server in Deutschland gesendet.
          </p>
          <p>
            Folgende Hauptinformationen werden von uns gespeichert und
            ausgewertet:
          </p>
          <ul>
            <li>IP-Adresse</li>
            <li>
              Geräteinformationen wie Marke, Modell und die Version Ihres
              Android-Systems.
            </li>
            <li>
              Ihre eingestellte Sprache, RAM, Festplatte, Bildschirmauflösung,
              etc.
            </li>
          </ul>
          <p>
            Wenn bei einer Suchdienstanfrage ein Fehler auftritt, wird Ihre
            Suchanfrage ebenfalls an uns übermittelt. Wir geben keine von uns
            gesammelten Informationen an Dritte weiter oder verkaufen sie.
          </p>

          <h3>Verwendung der gesammelten Informationen direkt durch uns</h3>

          <p>
            Die auf Ihrem Gerät gespeicherten Protokolldaten können zur Analyse
            eines auf Ihrem Gerät auftretenden Fehlers verwendet werden. Diese
            Informationen können von uns nur ausgewertet werden, wenn Sie uns
            die Log-Dateien manuell (z.B. per E-Mail) zusenden.
            <br />
            Die an unseren Server übermittelten Daten werden nur zur
            Nutzungsanalyse und zur Fehlerbehebung verwendet.
            <br />
            Wenn Sie es wünschen, löschen wir die mit Ihrer IP-Adresse
            gespeicherten Nutzungsdaten. Kontaktieren Sie uns über{" "}
            <a href="mailto:ImmoFinder24@gmail.com">ImmoFinder24@gmail.com</a>,
            wenn Sie die Löschung dieser Daten wünschen. Wir können keine Daten
            (Marke, Modell, Android-Version, ...) löschen, die nicht mit einer
            IP-Adresse verknüpft sind.
          </p>

          <h3>Datensicherheit auf unserem Server</h3>

          <p>
            Wir haben angemessene technische und organisatorische
            Sicherheitsmaßnahmen umgesetzt, um die Sicherheit der von uns
            verarbeiteten personenbezogenen Daten zu gewährleisten. Trotz
            unserer Sicherheitsvorkehrungen und Bemühungen, Ihre Daten zu
            schützen, kann jedoch nicht garantiert werden, dass die
            elektronische Übertragung über das Internet oder die
            Informationsspeicherung zu 100 % sicher ist, so dass wir nicht
            versprechen oder garantieren können, dass Hacker, Cyberkriminelle
            oder andere unbefugte Dritte nicht in der Lage sind, unsere
            Sicherheit zu überwinden und Ihre Daten unrechtmäßig zu sammeln,
            darauf zuzugreifen, sie zu stehlen oder zu verändern. Obwohl wir
            unser Bestes tun, um Ihre persönlichen Daten zu schützen, erfolgt
            die Übertragung von persönlichen Daten zu und von unserer App auf
            Ihr eigenes Risiko. Sie sollten nur innerhalb einer sicheren
            Umgebung auf die App zugreifen. Die Daten zwischen dem Server und
            der App sind über SSL gesichert.
          </p>

          <h3>Weitergabe und Sammlung von Daten an Dritte</h3>

          <p>
            Wir geben Daten nur mit Ihrem Einverständnis weiter, um Gesetze
            einzuhalten, um Ihnen Dienstleistungen zu erbringen, um Ihre Rechte
            zu schützen oder um geschäftliche Verpflichtungen zu erfüllen.
            <br />
            Diese App nutzt Dienste von Drittanbietern, die möglicherweise
            Informationen sammeln, mit denen Sie identifiziert werden können.
            Sie erklären sich mit dieser Datenschutzrichtlinie auch mit den
            Datenschutzrichtlinien der folgenden Drittanbieterdienste
            einverstanden, die unsere App nutzt:
          </p>
          <ul>
            <li>
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/admob/answer/6128543?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                AdMob
              </a>
            </li>
          </ul>

          <h3>Internationaler Datentransfer</h3>

          <p>
            Wie bereits erwähnt, befindet sich unser Server in Deutschland (EU).
            Wenn Sie diese App von außerhalb der EU nutzen, beachten Sie bitte,
            dass die Daten international übertragen werden.
            <br />
            Dienste von Drittanbietern (Google Play Services und AdMob) befinden
            sich möglicherweise außerhalb der Europäischen Union. Überprüfen Sie
            deren Datenschutzrichtlinien (zuvor verlinkt) für weitere
            Informationen.
          </p>

          <h3>Datenübertragung und Links zu anderen Seiten</h3>

          <p>
            Der Zweck dieser App ist es, Daten von Websites Dritter zu sammeln.
            Daher sammeln und speichern wir Daten von den Websites, die Sie in
            der App eingeben.
            <br />
            Wir sind nicht verantwortlich für die Daten, die Sie eingeben
            und/oder die Daten, die die eingegebene Website an uns übermittelt.
            Wir betreiben diese Websites nicht.
            <br />
            Wenn Sie auf einen Link zu einer Immobilienanzeige klicken, wird die
            Suchanfrage in einem externen Browser geöffnet.
            <br />
            Wir empfehlen Ihnen dringend, die Datenschutzrichtlinien dieser
            Websites zu lesen. Wir haben keine Kontrolle über und übernehmen
            keine Verantwortung für den Inhalt, die Datenschutzrichtlinien von
            Websites oder Diensten Dritter.
            <br />
            Zusätzlich zu den Links des Immobilienportals können Sie Anzeigen
            von AdMob sehen. Für diese gilt das Gleiche: Wir haben keine
            Kontrolle oder Verantwortung für den Inhalt und raten Ihnen außerdem
            dringend, die oben verlinkten Datenschutzrichtlinien von AdMob zu
            überprüfen.
          </p>

          <h3>Datenspeicherung bis zur Löschung</h3>

          <p>
            Wir bewahren Ihre IP-Adressdaten so lange auf, wie es zur Erfüllung
            der in dieser Datenschutzerklärung genannten Zwecke erforderlich
            ist, es sei denn, wir sind gesetzlich dazu verpflichtet. Kein Zweck
            in dieser Mitteilung erfordert, dass wir Ihre personenbezogenen
            Daten länger als 90 Tage aufbewahren.
          </p>

          <h3>Datenschutz für Kinder / Minderjährige</h3>

          <p>
            Wir sammeln keine Informationen über das Alter der Nutzer. Sollten
            wir feststellen, dass ein Minderjähriger uns persönliche
            Informationen zur Verfügung gestellt hat, werden wir diese
            Informationen sofort von unserem Server löschen.
            <br />
            Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und Sie
            wissen, dass Ihr Kind uns persönliche Informationen zur Verfügung
            gestellt hat, kontaktieren Sie uns bitte und wir werden diese
            Informationen löschen.
          </p>

          <h3>Cookies</h3>

          <p>
            Cookies sind Dateien mit einer kleinen Menge an Daten, die
            üblicherweise als anonyme eindeutige Identifikatoren verwendet
            werden. Diese werden von den Webseiten, die Sie besuchen, an Ihren
            Browser gesendet und im internen Speicher Ihres Geräts gespeichert.
          </p>

          <p>
            Dieser Dienst selbst verwendet nicht explizit Cookies. Die App kann
            jedoch Code und Bibliotheken von Drittanbietern verwenden, die
            Cookies für ihren Dienst nutzen. Sie können diese Cookies entweder
            akzeptieren oder ablehnen. Wenn Sie sie ablehnen, erhalten Sie
            möglicherweise nicht den vollen Dienst.
          </p>

          <h3>Dienstanbieter</h3>

          <p>
            Es kann sein, dass ich aus folgenden Gründen Drittfirmen und
            Einzelpersonen einsetze:
          </p>
          <ul>
            <li>Um unseren Service zu ermöglichen;</li>
            <li>Um den Dienst in Ihrem Namen zu erbringen;</li>
            <li>Um dienstbezogene Dienstleistungen zu erbringen; oder</li>
            <li>
              Um uns bei der Analyse der Nutzung des Dienstes zu unterstützen.
            </li>
          </ul>

          <p>
            Diese Dienste haben Zugriff auf Ihre personenbezogenen Daten. Der
            Grund dafür ist, dass sie die ihnen zugewiesenen Aufgaben in unserem
            Auftrag ausführen. Sie sind jedoch verpflichtet, nicht über die
            Informationen zu verfügen oder sie für andere Zwecke zu verwenden.
          </p>

          <h3>Informationen herunterladen</h3>

          <p>
            Bei jedem Start der App lädt die App eine kleine Menge an Daten zur
            internen Verwendung von unserem Dienst herunter. Diese Informationen
            werden von unserem Server in Deutschland (EU) heruntergeladen und
            mit SSL verschlüsselt.
            <br />
            Diese Informationen können auch verwendet werden, um Sie über ein
            Popup nach dem Start der App o.ä. mit Informationen zu informieren.
          </p>

          <h3>Aktualisierung dieses Hinweises</h3>

          <p>
            Wir können diesen Hinweis bei Bedarf aktualisieren, um von Zeit zu
            Zeit mit den relevanten Gesetzen konform zu bleiben.
            <br />
            Wir werden Sie informieren, wenn sich diese Informationen ändern.
            Trotzdem raten wir Ihnen, diese Seite regelmäßig zu überprüfen, um
            keine Aktualisierung zu verpassen.
          </p>

          <h3>Kontakt / Fragen</h3>

          <p>
            Wenn Sie Fragen, Anregungen oder Wünsche haben, wenden Sie sich
            bitte an Benjamin Nopper unter{" "}
            <a href="mailto:ImmoFinder24@gmail.com">ImmoFinder24@gmail.com</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default DataProtection;
