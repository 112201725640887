import React from "react";
import RealEstatePortals from "../realEstatePortals/realEstatePortals";
import "./left.css";

const Left = () => {
  return (
    <>
      <aside className="real-estate-portals">
        <RealEstatePortals />
      </aside>
    </>
  );
};

export default Left;
