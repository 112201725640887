import "./App.css";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/header/header";
import Body from "./components/body/body";
import Footer from "./components/footer/footer";

function App() {
  const [displayQrCode, setDisplayQrCode] = useState(false);

  const hideQrCode = () => {
    if (displayQrCode) {
      setDisplayQrCode(false);
    }
  };
  return (
    <>
      <div className="parent" onClick={hideQrCode}>
        <BrowserRouter>
          <Header setDisplayQrCode={setDisplayQrCode} />
          <Body
            setDisplayQrCode={setDisplayQrCode}
            displayQrCode={displayQrCode}
          />
          <Footer setDisplayQrCode={setDisplayQrCode} />
          <div className="overflow">
            {displayQrCode && (
              <div
                className="qr-code-parent"
                onClick={() => setDisplayQrCode(false)}
              >
                <p className="close"></p>
                <img
                  className="qr-code-big"
                  src="images/google-play-qr-code-big.png"
                  alt="Google Play QR-Code"
                />
              </div>
            )}
          </div>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
