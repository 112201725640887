import React from "react";
import "../body/left";
import "./realEstatePortals.css";

const RealEstatePortals = () => {
  return (
    <>
      <div className="realEstateCompontent">
        <p className="portals-title">Unterstützte Immobilienportale:</p>
        <ul className="realEstatePortals">
          <li>
            <a href="http://www.immowelt.de" target="_blank" rel="noreferrer">
              immowelt.de
            </a>
          </li>
          <li>
            <a href="http://www.wg-gesucht.de" target="_blank" rel="noreferrer">
              wg-gesucht.de
            </a>
          </li>
          <li>
            <a href="http://www.immonet.de" target="_blank" rel="noreferrer">
              immonet.de
            </a>
          </li>
          <li>
            <a
              href="http://www.ebay-kleinanzeigen.de"
              target="_blank"
              rel="noreferrer"
            >
              ebay-kleinanzeigen.de
            </a>
          </li>
          <li>
            <a
              href="http://www.1a-immobilienmarkt.de"
              target="_blank"
              rel="noreferrer"
            >
              1a-immobilienmarkt.de
            </a>
          </li>
          <li>
            <a href="http://www.markt.de" target="_blank" rel="noreferrer">
              markt.de
            </a>
          </li>
          <li>
            <a href="http://www.wunschimmo.de" target="_blank" rel="noreferrer">
              wunschimmo.de
            </a>
          </li>
          <li>
            <a
              href="http://www.wohnung-jetzt.de"
              target="_blank"
              rel="noreferrer"
            >
              wohnung-jetzt.de
            </a>
          </li>
          <li>
            <a href="http://www.vrm-immo.de" target="_blank" rel="noreferrer">
              vrm-immo.de
            </a>
          </li>
          <li>
            <a href="http://www.reedb.de" target="_blank" rel="noreferrer">
              reedb.de
            </a>
          </li>
          <li>
            <a href="http://www.nestoria.de" target="_blank" rel="noreferrer">
              nestoria.de
            </a>
          </li>
          <li>
            <a href="http://www.nuroa.de" target="_blank" rel="noreferrer">
              nuroa.de
            </a>
          </li>
          <li>
            <a href="http://www.mitula.com" target="_blank" rel="noreferrer">
              mitula.com
            </a>
          </li>
          <li>
            <a href="http://www.immobilien.de" target="_blank" rel="noreferrer">
              immobilien.de
            </a>
          </li>
          <li>
            <a
              href="http://www.immoversum.com"
              target="_blank"
              rel="noreferrer"
            >
              immoversum.com
            </a>
          </li>
          <li>
            <a href="http://www.freeimmo.at" target="_blank" rel="noreferrer">
              freeimmo.at
            </a>
          </li>
          <li>
            <a href="http://www.kip.net" target="_blank" rel="noreferrer">
              kip.net
            </a>
          </li>
          <li>
            <a href="http://www.immobilio.de" target="_blank" rel="noreferrer">
              immobilio.de
            </a>
          </li>
          <li>
            <a
              href="http://www.wohnungsboerse.net"
              target="_blank"
              rel="noreferrer"
            >
              wohnungsboerse.net
            </a>
          </li>
          <li>
            <a
              href="http://immobilien.all-in.de"
              target="_blank"
              rel="noreferrer"
            >
              immobilien.all-in.de
            </a>
          </li>
          <li>
            <a href="http://www.ovbimmo.de" target="_blank" rel="noreferrer">
              ovbimmo.de
            </a>
          </li>
          <li>
            <a href="http://www.immomorgen.de" target="_blank" rel="noreferrer">
              immomorgen.de
            </a>
          </li>
          <li>
            <a
              href="http://immo.rheinpfalz.de"
              target="_blank"
              rel="noreferrer"
            >
              immo.rheinpfalz.de
            </a>
          </li>
          <li>
            <a href="http://immo.swp.de" target="_blank" rel="noreferrer">
              immo.swp.de
            </a>
          </li>
          <li>
            <a href="http://immobilien.noz.de" target="_blank" rel="noreferrer">
              immobilien.noz.de
            </a>
          </li>
          <li>
            <a
              href="http://www.immoversum.com"
              target="_blank"
              rel="noreferrer"
            >
              immoversum.com
            </a>
          </li>
          <li>
            <a href="http://www.repimmo.com" target="_blank" rel="noreferrer">
              repimmo.com
            </a>
          </li>
          <li>
            <a href="http://www.kyero.com" target="_blank" rel="noreferrer">
              kyero.com
            </a>
          </li>
          <li>
            <a href="http://www.idealista.pt" target="_blank" rel="noreferrer">
              idealista.pt
            </a>
          </li>
          <li>
            <a href="http://www.immowelt.ch" target="_blank" rel="noreferrer">
              immowelt.ch
            </a>
          </li>
          <li>
            <a href="http://www.mitula.ch" target="_blank" rel="noreferrer">
              mitula.ch
            </a>
          </li>
          <li>
            <a href="http://www.homegate.ch" target="_blank" rel="noreferrer">
              homegate.ch
            </a>
          </li>
          <li>
            <a
              href="http://www.immoscout24.ch"
              target="_blank"
              rel="noreferrer"
            >
              immoscout24.ch
            </a>
          </li>
          <li>
            <a href="http://www.anibis.ch" target="_blank" rel="noreferrer">
              anibis.ch
            </a>
          </li>
          <li>
            <a href="http://www.icasa.ch" target="_blank" rel="noreferrer">
              icasa.ch
            </a>
          </li>
          <li>
            <a href="http://www.immostreet.ch" target="_blank" rel="noreferrer">
              immostreet.ch
            </a>
          </li>
          <li>
            <a href="http://www.comparis.ch" target="_blank" rel="noreferrer">
              comparis.ch
            </a>
          </li>
          <li>
            <a href="http://www.justimmo.ch" target="_blank" rel="noreferrer">
              justimmo.ch
            </a>
          </li>
          <li>
            <a
              href="http://www.betterhomes.ch"
              target="_blank"
              rel="noreferrer"
            >
              betterhomes.ch
            </a>
          </li>
          <li>
            <a href="http://www.repimmo.com" target="_blank" rel="noreferrer">
              repimmo.com
            </a>
          </li>
          <li>
            <a href="http://www.idealista.com" target="_blank" rel="noreferrer">
              idealista.com
            </a>
          </li>
          <li>
            <a
              href="http://www.habitaclia.com"
              target="_blank"
              rel="noreferrer"
            >
              habitaclia.com
            </a>
          </li>
          <li>
            <a href="http://www.pisos.com" target="_blank" rel="noreferrer">
              pisos.com
            </a>
          </li>
          <li>
            <a
              href="http://www.yaencontre.com"
              target="_blank"
              rel="noreferrer"
            >
              yaencontre.com
            </a>
          </li>
          <li>
            <a href="http://www.tucasa.com" target="_blank" rel="noreferrer">
              tucasa.com
            </a>
          </li>
          <li>
            <a href="http://www.fotocasa.es" target="_blank" rel="noreferrer">
              fotocasa.es
            </a>
          </li>
          <li>
            <a href="http://www.trovit.com" target="_blank" rel="noreferrer">
              trovit.com
            </a>
          </li>
          <li>
            <a href="http://www.immowelt.at" target="_blank" rel="noreferrer">
              immowelt.at
            </a>
          </li>
          <li>
            <a href="http://www.mitula.at" target="_blank" rel="noreferrer">
              mitula.at
            </a>
          </li>
          <li>
            <a
              href="http://www.immobilien.net"
              target="_blank"
              rel="noreferrer"
            >
              immobilien.net
            </a>
          </li>
          <li>
            <a href="http://www.immodirekt.at" target="_blank" rel="noreferrer">
              immodirekt.at
            </a>
          </li>
          <li>
            <a
              href="http://www.immobilienscout24.at"
              target="_blank"
              rel="noreferrer"
            >
              immobilienscout24.at
            </a>
          </li>
          <li>
            <a
              href="http://www.a-immobilienmarkt.at"
              target="_blank"
              rel="noreferrer"
            >
              a-immobilienmarkt.at
            </a>
          </li>
          <li>
            <a
              href="http://www.immobilienseiten.com"
              target="_blank"
              rel="noreferrer"
            >
              immobilienseiten.com
            </a>
          </li>
          <li>
            <a href="http://www.findmyhome.at" target="_blank" rel="noreferrer">
              findmyhome.at
            </a>
          </li>
          <li>
            <a href="http://immo.kurier.at" target="_blank" rel="noreferrer">
              immo.kurier.at
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default RealEstatePortals;
