import React from "react";
import { Link } from "react-router-dom";
import './footer.css'

const Footer = ({setDisplayQrCode}) => {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          <div className="google-play-text-parent">
            <a href="https://play.google.com/store/apps/details?id=com.bennoss.ImmoFinder24" target="_blank" rel="noopener noreferrer">
              <p className="google-play-text">ImmoFinder24 noch heute vom Google Play Store herunterladen:</p>
            </a>
          </div>

          <div className="google-play-flex">
            <a className="google-play-button-wrapper" href="https://play.google.com/store/apps/details?id=com.bennoss.ImmoFinder24" target="_blank" rel="noopener noreferrer">
              <img className="google-play google-play-footer" src="images/google-play-badge.png" alt="google play"/>
            </a>
            <img onClick={() => setDisplayQrCode(true)} className="qrcode-footer" src="images/google-play-qr-code.png" alt="google play qr code" />
          </div>
          
          <div className="footer-legal">
            <Link to="/">
              <p className="copyright">Copyright 2021 &#169; Benjamin Nopper</p>
            </Link>
            <Link to="/impressum">
              <p className="impressum">Impressum</p>
            </Link>
            <Link to="/dataProtection">
              <p className="datenschutz">Datenschutz</p>
            </Link>
          </div>
        </div>
        <div className="paypal-footer-container">
          <a href="https://www.paypal.com/donate?hosted_button_id=EL3Y58HQ6NXAS" target="_blank" rel="noreferrer">Paypal:</a>
          <form className="paypal-footer-button" action="https://www.paypal.com/donate" method="post" target="_blank">
                <input type="hidden" name="hosted_button_id" value="EL3Y58HQ6NXAS" />
                <input type="image" src="https://www.paypalobjects.com/de_DE/DE/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                <img alt="" border="0" src="https://www.paypal.com/de_DE/i/scr/pixel.gif" width="1" height="1" />
              </form>
        </div>
        <p className="google-trademark">Android, Google Play und das Google Play Logo sind Marken von Google LLC</p>
      </footer>
    </>
  );
};

export default Footer;